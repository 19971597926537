// =============================
// Imports
// =============================

// External Dependencies
import axios from 'axios';
import { FORM_ERROR } from 'final-form';
import dayjs from 'dayjs';
import _pick from 'lodash/pick';
import _get from 'lodash/get';

// Config
import { i18n } from '../../config/i18n';

// Constants
import * as acts from '../constants/ActionTypes';

// Helpers
import {
  getApiUrl,
  getXPreferredLanguage,
  snakeCaseKeysDeep,
  camelCaseKeysDeep,
} from '../../helpers/misc';
import determineError, { MewoError } from '../../helpers/errors';

// =============================
// Actions
// =============================

export function getUserData(token) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_DATA_LOADING,
    });

    try {
      const response = await axios({
        method: 'get',
        url: getApiUrl('public/users'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-auth': token,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });

      dispatch({
        type: acts.SET_USER_SESSION_TOKEN,
        payload: token,
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: camelCaseKeysDeep(response.data),
      });

      dispatch({
        type: acts.GET_USER_DATA_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.GET_USER_DATA_FAILURE,
      });

      throw new MewoError({ error: err });
    }
  };
}

export function getUserSessionToken({ email, password }) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.GET_USER_SESSION_TOKEN_LOADING,
    });

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl('public/users/session'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { email, password },
      });

      dispatch({
        type: acts.SET_USER_SESSION_TOKEN,
        payload: response.headers['x-auth'],
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: camelCaseKeysDeep(response.data),
      });

      return dispatch({
        type: acts.GET_USER_SESSION_TOKEN_SUCCESS,
      });
    } catch (err) {
      if (err.response && err.response.data.key === 'account_not_verified') {
        return dispatch({
          type: acts.GET_USER_SESSION_TOKEN_FAILURE,
          payload: {
            message: i18n.t('errors:auth.account_not_verified'),
            context: 'auth_invalid_access',
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }

      if (err.response && err.response.data.key === 'invalid_access') {
        return dispatch({
          type: acts.GET_USER_SESSION_TOKEN_FAILURE,
          payload: {
            message: determineError(err),
            context: 'auth_invalid_access',
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }

      dispatch({
        type: acts.GET_USER_SESSION_TOKEN_FAILURE,
      });

      switch (true) {
        case err.response && err.response.status === 429:
          return Promise.resolve({
            [FORM_ERROR]: i18n.t('errors:auth.too_many_tries', {
              nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
            }),
          });

        case err.response && err.response.data.key === 'wrong_credentials':
          return { [FORM_ERROR]: i18n.t('errors:auth.wrong_credentials') };

        default:
          return { [FORM_ERROR]: determineError(err) };
      }
    }
  };
}

export function registerUser(user) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.REGISTER_USER_LOADING,
    });

    try {
      const isHttps = getState().core.serverContext.locationProtocol === 'https:';
      const websiteUrl = `${getState().core.serverContext.xHost}/login?activateToken=`;

      const data = {
        ...snakeCaseKeysDeep(
          _pick(user, [
            'lastName',
            'firstName',
            'companyName',
            'industry',
            'email',
            'phoneNumber',
            'country',
            'password',
            'withNewsletter',
          ]),
        ),
        language: i18n.language,
        agree_to_tos: true,
        site_url: `${isHttps ? 'https' : 'http'}://${websiteUrl}`,
      };

      await axios({
        method: 'post',
        url: getApiUrl('public/users'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data,
      });

      return dispatch({
        type: acts.REGISTER_USER_SUCCESS,
        payload: {
          message: i18n.t('pages:register.register_success'),
        },
      });
    } catch (err) {
      if (err.response && err.response.data.key === 'invalid_access') {
        return dispatch({
          type: acts.REGISTER_USER_FAILURE,
          payload: {
            message: determineError(err),
            context: 'auth_invalid_access',
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }

      dispatch({
        type: acts.REGISTER_USER_FAILURE,
      });

      switch (true) {
        case err.response && err.response.status === 406:
          return { [FORM_ERROR]: i18n.t('errors:auth.email_already_taken') };

        default:
          return { [FORM_ERROR]: determineError(err) };
      }
    }
  };
}

export function sendRecoverPasswordMail({ email }) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.RECOVER_PASSWORD_LOADING,
    });

    try {
      const isHttps = getState().core.serverContext.locationProtocol === 'https:';
      const websiteUrl = `${getState().core.serverContext.xHost}/resetpassword`;

      await axios({
        method: 'post',
        url: getApiUrl('public/users/password/reset'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { email, site_url: `${isHttps ? 'https' : 'http'}://${websiteUrl}` },
      });

      return dispatch({
        type: acts.RECOVER_PASSWORD_SUCCESS,
      });
    } catch (err) {
      dispatch({
        type: acts.RECOVER_PASSWORD_FAILURE,
      });

      switch (true) {
        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          return { [FORM_ERROR]: i18n.t('errors:auth.user_not_found') };

        default:
          return { [FORM_ERROR]: determineError(err) };
      }
    }
  };
}

export function resetUserPassword({ password, token }) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.RESET_PASSWORD_LOADING,
    });

    try {
      const response = await axios({
        method: 'put',
        url: getApiUrl('public/users/password'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { password, token },
      });

      dispatch({
        type: acts.SET_USER_SESSION_TOKEN,
        payload: response.headers['x-auth'],
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: camelCaseKeysDeep(response.data),
      });

      return dispatch({
        type: acts.RESET_PASSWORD_SUCCESS,
      });
    } catch (err) {
      if (err.response && err.response.data.key === 'invalid_access') {
        return dispatch({
          type: acts.RESET_PASSWORD_FAILURE,
          payload: {
            message: determineError(err),
            context: 'auth_invalid_access',
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }

      dispatch({
        type: acts.RESET_PASSWORD_FAILURE,
      });

      switch (true) {
        case err.response && err.response.status === 429:
          return Promise.resolve({
            [FORM_ERROR]: i18n.t('errors:auth.too_many_tries', {
              nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
            }),
          });

        case err.response && err.response.status === 400:
          return {
            [FORM_ERROR]: i18n.t('errors:auth.reset_password_token_required'),
          };

        case err.response && err.response.status === 403:
          return {
            [FORM_ERROR]: i18n.t('errors:auth.reset_password_token_expired'),
          };

        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          return {
            [FORM_ERROR]: i18n.t('errors:auth.reset_password_token_not_found'),
          };

        default:
          return { [FORM_ERROR]: determineError(err) };
      }
    }
  };
}

export function activateUserAccount(token) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.ACTIVATE_ACCOUNT_LOADING,
    });

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl('public/users/register/activate/email'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: { token },
      });

      dispatch({
        type: acts.SET_USER_SESSION_TOKEN,
        payload: response.headers['x-auth'],
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: camelCaseKeysDeep(response.data),
      });

      return dispatch({
        type: acts.ACTIVATE_ACCOUNT_SUCCESS,
        payload: {
          message: i18n.t('pages:login.activate_success'),
        },
      });
    } catch (err) {
      let errorMsg;

      switch (true) {
        case err.response && err.response.status === 429:
          errorMsg = i18n.t('errors:auth.too_many_tries', {
            nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
          });
          break;

        case err.response && err.response.status === 400:
          errorMsg = i18n.t('errors:auth.activate_account_token_required');
          break;

        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          errorMsg = i18n.t('errors:auth.activate_account_token_not_found');
          break;

        // NOTE: There is a case of existing "invalid_access" error that can be handled here
        default:
          errorMsg = determineError(err);
          break;
      }

      return dispatch({
        type: acts.ACTIVATE_ACCOUNT_FAILURE,
        payload: {
          message: errorMsg,
          reqId: _get(err, 'response.data.reqId'),
        },
      });
    }
  };
}

export function setUserPassword(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.SET_PASSWORD_LOADING,
    });

    try {
      const response = await axios({
        method: 'post',
        url: getApiUrl('public/users/register/activate'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
        data: snakeCaseKeysDeep(_pick(data, [
          'password',
          'token',
          'withNewsletter',
        ])),
      });

      dispatch({
        type: acts.SET_USER_SESSION_TOKEN,
        payload: response.headers['x-auth'],
      });

      dispatch({
        type: acts.SET_USER_INFO,
        payload: camelCaseKeysDeep(response.data),
      });

      return dispatch({
        type: acts.SET_PASSWORD_SUCCESS,
      });
    } catch (err) {
      if (err.response && err.response.data.key === 'invalid_access') {
        return dispatch({
          type: acts.SET_PASSWORD_FAILURE,
          payload: {
            message: determineError(err),
            context: 'auth_invalid_access',
            reqId: _get(err, 'response.data.reqId'),
          },
        });
      }

      dispatch({
        type: acts.SET_PASSWORD_FAILURE,
      });

      switch (true) {
        case err.response && err.response.status === 429:
          return Promise.resolve({
            [FORM_ERROR]: i18n.t('errors:auth.too_many_tries', {
              nextTry: dayjs().to(dayjs(err.response.data.error.nextValidRequestDate)),
            }),
          });

        case err.response && err.response.status === 400:
          return {
            [FORM_ERROR]: i18n.t('errors:auth.set_password_token_required'),
          };

        case err.response
          && err.response.status === 404
          && err.response.data.key !== 'config_not_found':
          return {
            [FORM_ERROR]: i18n.t('errors:auth.set_password_token_not_found'),
          };

        default:
          return { [FORM_ERROR]: determineError(err) };
      }
    }
  };
}

export function logout() {
  return async (dispatch, getState) => {
    dispatch({
      type: acts.LOGOUT_USER_LOADING,
    });

    // Regardless of the consequence of this action, we will
    // Treat it as a success
    try {
      await axios({
        method: 'delete',
        url: getApiUrl('public/users/session'),
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'x-auth': getState().user.token,
          'x-host': getState().core.serverContext.xHost,
          'x-preferred-language': getXPreferredLanguage(),
        },
      });
      // eslint-disable-next-line no-empty
    } catch (error) {

    } finally {
      dispatch({
        type: acts.LOGOUT_USER_SUCCESS,
      });
    }
  };
}
