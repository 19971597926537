// =============================
// Imports
// =============================

import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import _get from 'lodash/get';

import { withTranslation } from '../../../../config/i18n';

import validator from '../../../../validators/modals/account';

import * as acts from '../../../../store/constants/ActionTypes';

import { getValueByLocale } from '../../../../helpers/i18n';
import * as pth from '../../../../helpers/proptypes';

import MewoInput from '../../../presentationals/mewoInput';
import { WrapIfDesktop } from '../../../other/responsive';

import {
  InputWrapper,
  ButtonWrapper,
  StyledForm,
  DoubleInputWrapper,
  StyledInput,
  StyledSelectInput,
  StyledCheckboxInput,
  StyledButton,
  AuthTitle,
  InputTitle,
  WrapperBase,
} from '../../auth/common.styles';
import { AuthModal } from '../styles';
import { Container, DownloadStatus, Status, TripleInputWrapper } from './styles';

// =============================
// Stories
// =============================

class ModalAccount extends PureComponent {
  static propTypes = {
    closeAccountModal: PropTypes.func.isRequired,
    i18n: PropTypes.shape({
      language: PropTypes.string,
    }).isRequired,
    modifyUserInfo: PropTypes.func.isRequired,
    modifyUserPassword: PropTypes.func.isRequired,
    opened: PropTypes.bool,
    t: PropTypes.func.isRequired,
    territories: PropTypes.arrayOf(
      PropTypes.shape({
        code: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    user: pth.fulluser,
    userIndustries: PropTypes.arrayOf(pth.userIndustry).isRequired,
    withMailchimp: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    opened: false,
    user: null,
  };

  pickFormData = user => ({
    ...user,
    industry: _get(user, 'industry.id'),
  });

  render() {
    const {
      closeAccountModal,
      i18n: { language },
      modifyUserInfo,
      modifyUserPassword,
      opened,
      t,
      territories,
      user,
      userIndustries,
      withMailchimp,
    } = this.props;

    const countryOptions = territories.map(({ code, name }) => ({ label: name, value: code }));
    const canDownload = _get(user, 'canDownload', false);
    const canDownloadStems = _get(user, 'canDownloadStems', false);

    return (
      <AuthModal bottomCloseBtn name="account" noFade onClose={closeAccountModal} opened={opened}>
        <WrapIfDesktop Wrapper={<WrapperBase isModal />}>
          <Container>
            <Form onSubmit={modifyUserInfo} initialValues={this.pickFormData(user)}>
              {({ handleSubmit, submitting, pristine }) => (
                <StyledForm onSubmit={handleSubmit}>
                  <AuthTitle withSubInfo>{t('components:modal_account.title')}</AuthTitle>

                  <DownloadStatus>
                    {t('components:modal_account.download_status')}
                    &nbsp;
                    <Status canDownload={canDownload || canDownloadStems}>
                      {canDownload
                        && canDownloadStems
                        && t('components:modal_account.can_download_all')}
                      {canDownload
                        && !canDownloadStems
                        && t('components:modal_account.can_download_audiofiles')}
                      {!canDownload
                        && canDownloadStems
                        && t('components:modal_account.can_download_stems')}
                      {!canDownload
                        && !canDownloadStems
                        && t('components:modal_account.can_not_download')}
                    </Status>
                  </DownloadStatus>

                  <InputWrapper>
                    <DoubleInputWrapper>
                      <Field
                        name="lastName"
                        component={MewoInput}
                        use={StyledInput}
                        type="text"
                        placeholder={t('common:form.last_name')}
                        validate={validator.lastName}
                      />
                      <Field
                        name="firstName"
                        component={MewoInput}
                        use={StyledInput}
                        type="text"
                        placeholder={t('common:form.first_name')}
                        validate={validator.firstName}
                      />
                    </DoubleInputWrapper>
                    <DoubleInputWrapper>
                      <Field
                        name="email"
                        component={MewoInput}
                        use={StyledInput}
                        type="text"
                        placeholder={t('common:form.email')}
                        validate={validator.email}
                      />
                    </DoubleInputWrapper>
                    <DoubleInputWrapper>
                      <Field
                        name="phoneNumber"
                        component={MewoInput}
                        use={StyledInput}
                        type="text"
                        placeholder={t('common:form.phone_number')}
                        validate={validator.phoneNumber}
                      />
                      <Field
                        name="companyName"
                        component={MewoInput}
                        use={StyledInput}
                        type="text"
                        placeholder={t('common:form.company_name')}
                      />
                    </DoubleInputWrapper>
                    <DoubleInputWrapper>
                      <Field
                        name="industry"
                        component={MewoInput}
                        use={StyledSelectInput}
                        type="text"
                        placeholder={t('common:form.industry')}
                        options={userIndustries.map(({ id, names }) => ({
                          value: id,
                          label: getValueByLocale(names, language),
                        }))}
                        selectAllHelper={false}
                      />
                      <Field
                        name="country"
                        component={MewoInput}
                        use={StyledSelectInput}
                        type="text"
                        placeholder={t('common:form.country')}
                        options={countryOptions}
                        lang={{}}
                        selectAllHelper={false}
                        searchable
                      />
                    </DoubleInputWrapper>
                  </InputWrapper>
                  {withMailchimp && (
                    <InputWrapper>
                      <Field
                        name="withNewsletter"
                        component={MewoInput}
                        use={StyledCheckboxInput}
                        type="checkbox"
                        label={t('common:form.newsletter')}
                      />
                    </InputWrapper>
                  )}
                  <ButtonWrapper>
                    <StyledButton type="submit" disabled={submitting || pristine}>
                      {t('components:modal_account.update')}
                    </StyledButton>
                  </ButtonWrapper>
                </StyledForm>
              )}
            </Form>
            <Form onSubmit={modifyUserPassword}>
              {({ handleSubmit, form, submitting, pristine, values }) => (
                <StyledForm
                  onSubmit={async (event) => {
                    const res = await handleSubmit(event);
                    if (res && res.type === acts.MODIFY_USER_PASSWORD_SUCCESS) {
                      Object.keys(values).forEach((key) => {
                        form.change(key, undefined);
                        form.resetFieldState(key);
                      });
                    }
                  }}
                >
                  <InputWrapper>
                    <InputTitle>{t('components:modal_account.change_password')}</InputTitle>
                    <TripleInputWrapper>
                      <Field
                        name="currentPassword"
                        component={MewoInput}
                        use={StyledInput}
                        type="password"
                        placeholder={t('common:form.current_password')}
                        validate={validator.currentPassword}
                      />
                      <Field
                        name="password"
                        component={MewoInput}
                        use={StyledInput}
                        type="password"
                        placeholder={t('common:form.new_password')}
                        validate={validator.password}
                      />
                      <Field
                        name="passwordConfirmation"
                        component={MewoInput}
                        use={StyledInput}
                        type="password"
                        placeholder={t('common:form.password_confirmation')}
                        validate={validator.passwordConfirmation(
                          values.password,
                          t('common:form.new_password'),
                        )}
                      />
                    </TripleInputWrapper>
                  </InputWrapper>
                  <ButtonWrapper>
                    <StyledButton type="submit" disabled={submitting || pristine}>
                      {t('components:modal_account.update')}
                    </StyledButton>
                  </ButtonWrapper>
                </StyledForm>
              )}
            </Form>
          </Container>
        </WrapIfDesktop>
      </AuthModal>
    );
  }
}

export default withTranslation(['common', 'components'])(ModalAccount);
