/* eslint-disable import/prefer-default-export */
// =============================
// Imports
// =============================

// External Dependencies
import _get from 'lodash/get';

// =============================
// Helpers
// =============================

/**
 * Get root route of the application
 * @param {object} config - Config data object
 * @return {string} - Root route of the application
 */
export const rootRoute = (config) => {
  if (_get(config, 'customisations.homepage.disableAccess', false)) return '/search';
  return '/';
};
